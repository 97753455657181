input[type='text'] {
  width: 90%;
}

.SearchForm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

code {
  white-space: break-spaces;
}

button {
  padding: 5px 40px;
  margin: 20px 0;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #4285f4;
  color: white;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SearchForm-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

#headerText {
  font-size: 1.5em;
}

#logo {
  width: 200px;
}

@media (min-width: 580px) {
  .SearchForm-header {
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    align-items: baseline;
    margin: 0;
  }

  input[type='text'] {
    width: 450px;
    padding: 5px;
    border-radius: 5px;
  }
}
