$blue-text: var(--resume-text-accent);

.verizon-experience-container {
    display: flex;
    flex-direction: column;
    font-size: 1.25rem;
    gap: 20px;

    .verizon-project-navigation {
        display: flex;
        font-size: 1.5em;
        justify-content: space-between;
        cursor: pointer;

        .verizon-project-button {
            font-weight: bold;
            width: 50%;
            border-bottom: 2px solid;
            padding: 5px 10px;
            border-color: black;
            opacity: 0.3;

            &:last-of-type {
                text-align: right;
            }

            &.dark {
                border-color: rgb(194, 194, 194);
            }

            &.selected {
                opacity: 1;
                border: 2px solid;
                border-bottom: none;
                pointer-events: none;
            }
        }
    }

    .collab-experience-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        padding: 15px;
        border: 3px dotted rgb(194, 194, 194);
        position: relative;
        margin-bottom: 100px;
        overflow: hidden;

        p {
            margin-bottom: 0;
        }

        &:last-of-type {
            margin-bottom: 0;
        }

        .collab-experience-text {
            display: flex;
            flex-direction: column;
            gap: 8px;
            opacity: 0;
            transition: opacity 1.3s ease;

        }

        .collab-experience-image {
            position: relative;
            display: flex;
            width: 210px;
            height: 170px;
            justify-content: center;
            cursor: zoom-in;

            img {
                position: absolute;
                width: 100%;
                transition: left 0.5s ease, top 0.5s ease, opacity 0.5s ease;

                .visible {
                    left: 0px;
                    opacity: 1;
                }
            }
        }
    }

    .studio-experience-section {
        border-top: 3px dotted rgb(194, 194, 194);

        .studio-dashboard-image-group {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 300px;
            margin: 0 auto;

            .studio-dashboard-image {
                display: flex;
                flex-direction: column;
                align-items: center;

                &-caption {
                    font-size: 1.1rem;
                    font-style: italic;
                    padding: 5px 0;
                }

                > img {
                    cursor: zoom-in;
                    width: 100%;
                    object-fit: contain;
                }
            }
        }
    }

    .problem-solution {
        font-weight: bold;
        text-decoration: underline;
        text-transform: uppercase;
        color: $blue-text;
        margin-right: 6px;
    }

}

.studio-modal {
    &-background {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        font-size: 1rem;
    }

    &-content {
        position: relative;
        margin-top: -25px;
        max-width: 100vw;
        max-height: 100vh;
        cursor: zoom-out;

        .studio-modal-caption {
            position: absolute;
            white-space: nowrap;
            min-width: fit-content;
            text-align: center;
            padding: 5px;
            border-radius: 3px;
            text-transform: uppercase;
            font-weight: bold;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            box-shadow: 1px 1px 3px black;
            background: white;
        }

        > img {
            max-width: 100vw;
            max-height: calc(100vh - 40px);
            object-fit: contain;
        }
        
    }
}

@media (min-width: 680px) {
    .verizon-experience-container {
        font-size: 1.4rem;

        .collab-experience-section {
            padding: 30px;
            .collab-experience-image {
                width: 350px;
                height: 285px;
            }
        }
    }

}

@media (min-width: 920px) {
    .verizon-experience-container {
        .verizon-project-navigation {
            font-size: 1.8em;
        }
        .collab-experience-section {
            flex-direction: row;

            .collab-experience-text {
                width: calc(50% - 10px);
            }
            .collab-experience-image {
                height: 240px;
                width: calc(50% - 10px);
                
                > img {
                    max-height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .verizon-experience-container {
        .collab-experience-section {
            .collab-experience-image {
                height: 300px;
            }
        }
    }
}