$dark-background: rgba(50, 50, 50);
$dark-text: rgb(194, 194, 194);

html {
    font-size: 62.5%;
}

hr {
    margin: 0;
    border: none;
    border-bottom: 1px solid $dark-text;
}

.app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    position: absolute;
    padding-top: 55px;

    a {
        text-decoration: none;
        color: unset;
        cursor: unset;
    }

    .app-page {
        display: flex;
        justify-content: center;
        width: 100%;

        min-height: calc(100vh - 84px);
    }

    .page-container {
        margin: 60px 0;
        padding: 20px 10px;
        border-radius: 5px;
        border: 1.5px solid #4a4a4a;
        width: 88%;
        max-width: 920px;
        box-sizing: border-box;

        &.dark {
            border-color: $dark-text;
        }
    }

    &.dark {
        background:$dark-background;
        color: $dark-text;
    }

    .app-topbar {
        display: flex;
        position: fixed;
        z-index: 100;
        top: 0;
        width: calc(100% - 10px);
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        gap: 20px;
        font-family: 'Fira Sans', sans-serif;
        border-bottom: 1px solid black;
        background: white;

        &.dark {
            background:rgb(80, 80, 80);
        }

        .kenny-heagle-logo {
            display: inline-block;
            padding: 2px;
            margin: 0 8px;
            width: 20px;
            height: 20px;
            background-image: url(../public/logo128.png);
            background-size: contain;
            
            &.dark {
                background-image: url(../public/logo128-gray.png);
            }
        }
    }

    .app-header {
        text-transform: uppercase;
        display: flex;
        cursor: pointer;
        white-space: nowrap;
        font-size: 1.8rem;
        padding: 15px 0;
        text-align: center;
    }

    .app-theme-icon {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
        cursor: pointer;
    }

    .app-navigation-menu-button {
        cursor: pointer;
        padding: 10px;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.05);
        }
    }

    .app-navigation {
        display: flex;
        height: 0;
        overflow: hidden;
        flex-direction: column;
        position: absolute;
        top: 59px;
        right: 0;
        color: black;
        background: #5a5a5a;
        border-radius: 3px;
        align-items: center;
        transition: height 0.2s ease;

        &.dark {
            color: $dark-text;
        }

        &.expanded {
            height: unset;
            color: black;
            border: 1px solid $dark-text;
        }

        a {
            width: 100%;
        }

        .navigation-button {
            text-align: center;
            text-transform: uppercase;
            padding: 15px 40px;
            width: 100%;
            box-sizing: border-box;
            border-bottom: 0.5px solid black;
            cursor: pointer;

            &.selected {
                font-weight: bold;
                text-decoration: underline;
                color: gray;
                pointer-events: none;
            }
        }
    }
}

.navigation-dropdown-submenu {
    a {
        font-family: 'Fira Sans', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        color: $dark-text;
        cursor: unset;
    }

    .navigation-dropdown-button {
        font-size: 1.2rem;
        padding: 15px 30px;
        cursor: pointer;
        text-align: center;
        &:hover {
            background-color: #3a3a3a;
            opacity: 0.8;
        }

        &.selected {
            font-weight: bold;
            text-decoration: underline;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
    
}

@media (min-width: 480px) {
    .app-container {
        padding-top: 76px;

        .app-topbar {
            width: calc(100% - 40px);
            padding: 10px 20px;
        }

        .app-header {
            font-size: 2.2rem;
        }

        .app-navigation {
            top: 83px;
        }
    }
}

@media (min-width: 580px) {
    .app-container {
        .app-topbar {
            width: calc(100% - 80px);
            padding: 10px 40px;
        }

        .page-container {
            padding: 40px 30px;
        }

    }
}

@media (min-width: 680px) {
    .app-container {

        .app-navigation-menu-button {
            display: none;
        }

        .app-navigation {
            position: unset;
            height: unset;
            flex-direction: row;
            gap: 30px;
            border: none;
            background: unset;

            .navigation-button {
                padding: 0;
                border: none;
                width: unset;
            }
            
        }
    }
    
}