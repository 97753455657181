.Question {
  border: 1px solid black;
  word-break: break-word;
  border-radius: 5px;
  margin: 0 auto;
  margin-bottom: 15px;
  width: 90%;
  max-width: 900px;
  background-color: #a7bdd83b;
  transition: transform 0.2s, margin 0.2s;
  scrollbar-color: green rgb(250, 237, 165);
}

.Question:hover {
  background-color: #d2ddeb3b;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.397);
  cursor: pointer;
}

.Question-body {
  font-size: 0.8em;
  max-height: 20em;
  overflow-y: auto;
}

.Question-title {
  font-size: 0.9em;
  font-weight: 600;
  border-bottom: 1px solid gray;
}

.Question-footer {
  font-size: 0.8em;
  border-top: 1px solid gray;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.Question-stats {
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  justify-content: space-between;
}

.Question-tags {
  
  display: flex;
  justify-content: flex-end;
  word-break: break-all;
  width: auto;
  flex-direction: column;
  align-items: flex-end;
}

.Question-tag {
  margin-left: 10px;
}

/*ALTERNATE: Only question title displayed, body and footer appear on hover*/
/* .Question-body,
.Question-footer {
  display: none;
}

.Question:hover > .Question-body {
  display: block;
}

.Question:hover > .Question-footer {
  display: flex;
} */

@media (min-width: 580px) {
  .Question {
    padding: 1em;
    margin-bottom: 25px;
  }
  .Question-title {
    font-size: 1em;
  }
  .Question-footer {
    font-size: 1em;
    flex-wrap: wrap;
  }
  .Question-tags {
    word-break: normal;
  }
}

@media (min-width: 768px) {
  .Question-stats {
    flex-direction: row;
    flex-basis: 100%;
    margin-top: 5px;
  }
  .Question-tags {
    margin-top: 15px;
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
  }
}