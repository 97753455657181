a {
  color: hsl(194, 61%, 56%);
}

.project-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .demo-project-description {
    margin: 30px 10px;
    max-width: 600px;
  }

  .project-navigation {
    display: flex;
    width: 75%;
    margin-top: 30px;
    justify-content: center;
    align-items: flex-end;
    gap: 50px;

    .project-navigation-button {
      width: 120px;
      padding: 10px;
      cursor: pointer;
      text-align: center;
      background: #cccccc;
      border-radius: 50px;
      border: 0.5px solid black;
      transition: transform 0.2s ease;

      &.dark{
        color: black;
        background: rgb(194, 194, 194);

        &:hover {
          transform: scale(1.02);
          box-shadow: 1px 1px 2px rgb(141, 141, 141);
        }
      }

      &:hover {
        transform: scale(1.02);
        box-shadow: 1px 1px 2px black;
      }
    }
  }
}