body {
    font-size: 1.4rem;
}

input {
    height: 25px;
    padding: 5px 10px;
}

table {
    border-collapse: collapse;
    border: 0.5px solid rgb(84, 84, 84);
}

thead {
    height: 50px;
    font-weight: bold;
    border-bottom: 2px solid rgb(84, 84, 84);
}

td {
    width: 120px;
    padding: 10px 0;
    text-align: center;
    border-right: 0.5px solid rgb(84, 84, 84);
}

td:last-of-type {
    border: none;
}

.amortization-schedule {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 50px;
}

.amortization-schedule-header {
    display: flex;
    flex-direction: column;
    width: 95%;
    max-width: 600px;
    gap: 30px;
    border-bottom: 0.5px solid black;
}

.loan-terms-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: space-between;
    margin-bottom: 20px;
    gap: 10px;
}

.loan-term-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.loan-cost-container {
    display: flex;
    flex-direction: column;
    padding: 8px;
    gap: 20px;
    align-items: flex-start;
    width: 75%;
    max-width: 500px;
    box-sizing: border-box;
    border: 1px solid lightgray;
}

.loan-cost-information {
    font-weight: bold;
}

.red-text {
    color:rgb(248, 35, 110);
}

.amortization-payment-container {
    width: 75%;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.payment-row.gray-background {
    background-color: rgb(235, 235, 235)
}

.payment-row.gray-background.dark {
    background-color: rgb(80, 80, 80);
}

@media (min-width: 580px) {
    body {
        font-size: 1.6rem;
    }

    .amortization-schedule-header {
        width: 80%;
    }
}

@media (min-width: 870px) {

    .loan-terms-container {
        flex-direction: row;
    }

    .loan-term-input {
        flex-direction: column;
        gap: 10px;
    }
}