$blue-text: var(--resume-text-accent);
$gray-text: rgb(125, 125, 125);

.resume-container {
    max-width: 320px;
    font-size: 1.15rem;
    font-family: 'Fira Sans', sans-serif;

    .resume-title-block {
        //font-family: 'Work Sans', sans-serif;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 5px;
        padding-bottom: 20px;
        border-bottom: 0.5px solid black;

        .title-block-name {
            font-size: 2.2em;

            .first-name {
                color: gray;
                font-weight: normal;
            }
        }

        .title-block-physical-contact {
            color: gray;

            .contact-separator {
                width: 2px;
                height: 2px;
                vertical-align: middle;
                margin: 0 5px 1.5px 5px;
                display: inline-block;
                border-radius: 4px;
                background: $blue-text;
            }
        }

        .title-block-email {
            font-weight: bold;
            color: $blue-text;
        }
    }

    .personal-statement {
        margin-top: 15px;
        text-align: center;
    }

    .header-text {
        font-size: 1.15em;
        text-transform: uppercase;
        font-weight: bold;
        text-transform: uppercase;
        font-family: Georgia, 'Times New Roman', Times, serif;
    }

    .resume-section {
        margin: 20px 0;
        display: flex;
        flex-direction: column;

        .header-text {
            margin-bottom: 15px;
        }

        .resume-section-content {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-top: 10px;
            border-left: 2px dotted gray;
            overflow: hidden;
        }

        .experience-item-container {
            opacity: 1;
            margin-bottom: 15px;
        }

        .experience-item-dates {
            font-size: .925em;
            font-weight: bold;
            font-family: 'IBM Plex Sans', sans-serif;
            text-transform: uppercase;
            margin-bottom: 3px;
        }

        .experience-item-assignment {
            font-family: 'IBM Plex Sans', sans-serif;
            display: flex;
            justify-content: space-between;
            font-weight: bold;
            margin-bottom: 3px;
            text-transform: uppercase;

            .experience-item-position {
                color: $blue-text;
            }

            .experience-item-venue {
                padding-left: 8px;
                text-align: right;
                color: $gray-text;
            }

            .experience-item-separator {
                display: none;
            };
        }

        .experience-item-description {
            white-space: pre-line;
        }

        .experience-section-expand {
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
            color: $blue-text;
            margin-top: -15px;
            text-transform: uppercase;
            font-size: 0.75em;
        }
    }

    .skills-section {
        .skills-list-container {
            display: flex;

            ul {
                padding-left: 12px;

                li::marker {
                    color: $blue-text;
                }
            }

            .skills-item {
                margin-bottom: 5px;
            }
        }
    }
}

@media (min-width: 480px) {
    .resume-container {
        max-width: 400px;
    }

}

@media (min-width: 580px) {

    .resume-container {
        max-width: 500px;
        padding: 20px 40px;

        .skills-section {
            ul {
                columns: 2;
                -webkit-columns: 2;
                -moz-columns: 2;
            }
        }
    }
}

@media (min-width: 810px) {
    .resume-container {
        font-size: 1.25rem;
        max-width: 590px;
        padding: 50px 80px;

        .resume-section {
            .experience-item-assignment {
                justify-content: flex-start;
                gap: 5px;

                .experience-item-venue {
                    padding-left: 0;
                    text-align: left;
                }

                .experience-item-separator {
                    display: inline;
                }
            }
        }
    }
}